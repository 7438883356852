import React, { useRef, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Link as Element } from "react-scroll";


import "./SliderCategory.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import ProductCard from "../../Components/ProductCard/ProductCard";
import { useGetProductsByCategoryIdQuery } from "../../app/store";
import { Link } from "react-router-dom";

function SliderCategory(props) {
  const { data } = useGetProductsByCategoryIdQuery(props.catId);

  const [slideBegOrNot, handleSlideByState] = useState({
    isFirst: true,
    isLast: false,
  });
  const SlideRef = useRef();

  const handleNext = () => {
    SlideRef.current.swiper.slideNext();
  };

  const handlePrev = () => {
    SlideRef.current.swiper.slidePrev();
  };

  const onSlideChange = (swiper) => {
    handleSlideByState({
      isFirst: swiper.isBeginning,
      isLast: swiper.isEnd,
    });
  };

  const { isLast, isFirst } = slideBegOrNot;

  return (
    <div className="items-swiper" id={props.id} key={props.keyProp}>
      <div className="swiper-top">
        <div className="swiper-cat-name">
          <span className="blueline"></span>
          <Link to={`/category/${props.catId}`}>{props.category_name}</Link>
        </div>
        <div className="swiper-pag-nav">
          <MdKeyboardArrowLeft
            style={{
              color: "#50bdff",
              fontSize: "2em",
              cursor: "pointer",
              zIndex: "100",
            }}
            className={`Arrow ${isFirst ? "disabled" : ""}`}
            onClick={handlePrev}
          />
          <p
            className={`swiper-paginations${props.catId} general-paginations`}
          ></p>
          <MdKeyboardArrowRight
            style={{
              color: "#50bdff",
              fontSize: "2em",
              cursor: "pointer",
              zIndex: "100",
            }}
            className={`Arrow ${isLast ? "disabled" : ""}`}
            onClick={handleNext}
          />
        </div>
      </div>

      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        className={"mySwiper"}
        ref={SlideRef}
        onSlideChange={onSlideChange}
        pagination={{
          el: `.swiper-paginations${props.catId}`,
          type: "fraction",
        }}
        navigation={false}
        modules={[Pagination, Navigation]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          365: {
            slidesPerView: 2,
            spaceBetween: 12,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 29,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 33,
          },

          1920: {
            slidesPerView: 5,
            spaceBetween: 33,
          },
        }}
      >
        {data &&
          data.map((product) => (
            <SwiperSlide>
              <ProductCard
                key={product.id}
                id={product.id}
                image_url={product.image_url}
                title={product.title}
                grammage={product.grammage}
                price={product.price}
                discount={product.discount}
                discounted_price={product.discounted_price}
                index={product.index}
                bestseller={product.bestseller}
                na_zakaz={product.na_zakaz}
                newdish={product.newdish}
                vegan={product.vegan}
                meal={product.meal}
                is_special_offer={product.is_special_offer}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default SliderCategory;
