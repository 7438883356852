import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout, removeUser } from "../../features/user/userSlice";

export default function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout()).then(() => {
      dispatch(removeUser());
      navigate("/login");
    });
  }, [dispatch, navigate]);

  return <div></div>;
}
