import logoBlack from "../../images/logoBlack.svg";
import mastercard from "../../images/mastercard.svg";
import visa from "../../images/visa.svg";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import "./Footer.css";
import { useTranslation } from "react-i18next";


function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer--container">
        <div className="footer--el footer--logo">
          <a href="/">
            <img src={logoBlack} alt="Svizha" />
          </a>
          <p>© Svizha. All rights reserved</p>
        </div>

        <nav className="footer--nav">
          <div className="footer--el">
            <h6 className="footer--heading">{t("footer.Navigation")}</h6>
            <ul className="footer--ul">
              <li>

                <a href="/buy-us">{t("footer.Delivery")}</a>
              </li>
              <li>
                <a href="/menu">{t("footer.Menu")}</a>
              </li>
              <li>
                <a href="/">{t("footer.Main")}</a>
              </li>
              <li>
                <a href="/about-us">{t("footer.Additional_Information")}</a>
              </li>
            </ul>
          </div>

          <div className="footer--el">
            <h6 className="footer--heading">{t("footer.Make_an_order")}</h6>
            <a href="tel:380-68-978-21-85">+38 (096) 053 53 46</a>

            <div className="cards">
              <img src={visa} alt="visa" />
              <img src={mastercard} alt="mastercard" />
            </div>
          </div>

          <div className="footer--el">
            <h6 className="footer--heading">
              {t("footer.Treat_for_what_is_useful")} <br /> {t("footer.information_about")} <br /> {t("footer.food_here")}
            </h6>

            <div className="cards">
              <a href="https://www.facebook.com/sviizha">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="https://www.instagram.com/sviizha_">
                <img src={instagram} alt="instagram" />
              </a>
            </div>
          </div>
          <div className="footer--el">
            <h6 className="footer--heading">{t("footer.Work_schedule")}</h6>
            <p>{t("footer.from")}</p>
          </div>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
