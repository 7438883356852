import ProductCard from "../../Components/ProductCard/ProductCard";
import arrowBack from "../../images/arrow-back.png";
import "./CategoryProductsPage.css";
import { useGetProductsByCategoryIdQuery } from "../../app/store";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading/Loading";
import { useTranslation } from "react-i18next";

function CategoryProductsPage() {
  let { categoryId } = useParams();
  const { data, error, isLoading } =
    useGetProductsByCategoryIdQuery(categoryId);
  const { t } = useTranslation();

  return (
    <main>
      <div className="category--product--page--container">
        <div className="category--product--page--desc">
          <a href="/menu" className="back">
            <img src={arrowBack} alt="" />
            <span>{t("CategoryProductsPage.CategoryProductsPage")}</span>
          </a>
          {/* <h1>{catName}</h1> */}
          <h1 className="hide">{"categoryId"}</h1>
        </div>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <p>An error occured</p>
        ) : (
          <>
            {data.map((product) => (
              <ProductCard
                id={product.id}
                image_url={product.image_url}
                title={product.title}
                grammage={product.grammage}
                price={product.price}
                discount={product.discount}
                discounted_price={product.discounted_price}
                bestseller={product.bestseller}
                na_zakaz={product.na_zakaz}
                newdish={product.newdish}
                meal={product.meal}
                vegan={product.vegan}
                is_special_offer={product.is_special_offer}
              />
            ))}
          </>
        )}
      </div>
    </main>
  );
}

export default CategoryProductsPage;
