import { useState, useEffect } from "react";
import "./PersonalCabinet.css";
import Loading from "../../Components/Loading/Loading";
import { selectUser } from "../../features/user/userSlice";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { refreshTheToken } from "../../features/user/userSlice";
import store from "../../app/store";
import { useTranslation } from "react-i18next";

function PersonalCabinet() {
  const { t } = useTranslation();

  const [activeButton, setActiveButton] = useState("myorders");
  const [contentClass, setContentClass] = useState("myorders--section");
  const navigate = useNavigate();

  const { accessToken, user_id } = useSelector(selectUser);

  const [userData, setUserData] = useState(null);
  const [userOrdersData, setUserOrdersData] = useState(null);

  useEffect(() => {
    if (accessToken && user_id) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            `https://svizhabe.herokuapp.com/user/${user_id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setUserData(response.data);
        } catch (error) {
          store.dispatch(refreshTheToken());
        }
      };

      const fetchUserOrdersData = async () => {
        try {
          const response = await axios.get(
            `https://svizhabe.herokuapp.com/orders/user-orders/${user_id}`
          );
          setUserOrdersData(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchUserData();
      fetchUserOrdersData();
    } else {
      navigate("/login");
    }
  }, [accessToken, user_id, navigate]);

  function convertDate(dateValue) {
    const date = new Date(dateValue);
    return `${("0" + date.getDate()).slice(-2)}.${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}.${date.getFullYear()}`;
  }

  function convertDateTime(dateValue) {
    const date = new Date(dateValue);
    return `${date.getDate().toString().padStart(2, "0")}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}.${date.getFullYear().toString()}`;
  }

  return (
    <main>
      <div className="personal-account-container">
        <h1 className="personal-account-heading">
          {t("PersonalCabinet.Personal_office")}
        </h1>
        <span className="mobile-account-delimiter"></span>

        <div className="personal-account-info">
          <div className="account-info-el">
            <p>{t("PersonalCabinet.name")}</p>
            <h3>{userData?.first_name}</h3>
          </div>

          <div className="account-info-el">
            <p>{t("PersonalCabinet.last_name")}</p>
            <h3>{userData?.last_name}</h3>
          </div>

          <div className="account-info-el">
            <p>{t("PersonalCabinet.Registration_Date")}</p>
            <h3>{convertDate(userData?.date_joined)}</h3>
          </div>
        </div>

        <div className="personal-account-buttons">
          <button
            className={activeButton === "myorders" ? "button--active" : ""}
            onClick={() => {
              setActiveButton("myorders");
              setContentClass("myorders--section");
            }}
          >
            {t("PersonalCabinet.My_orders")}
          </button>
          <button
            className={activeButton === "sales" ? "button--active" : ""}
            onClick={() => {
              setActiveButton("sales");
              setContentClass("sales--section");
            }}
          >
            {t("PersonalCabinet.Discounts")}
          </button>
          {/* <button
            className={activeButton === "coupons" ? "button--active" : ""}
            onClick={() => {
              setActiveButton("coupons");
              setContentClass("coupons--section");
            }}
          >
            Персональні купони
          </button> */}
        </div>

        <div
          className="personal-account-orders"
          style={
            contentClass === "myorders--section"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {userOrdersData?.map((order) => (
            <div className="orders-el">
              <div className="orders-el-top">
                <h5>
                  {t("PersonalCabinet.Order")} №{order.id}
                </h5>
                <p>{t("PersonalCabinet.Data")}</p>
                <p>{t("PersonalCabinet.amount")}</p>
                <p>{t("PersonalCabinet.sum")}</p>
              </div>

              <div className="blue-divider"></div>
              <h5 className="mobile-order-num">
                {t("PersonalCabinet.Order")} №{order.id}
              </h5>

              {order.order_items.map((order_item) => (
                <>
                  <div className="orders-el-top">
                    <div className="orderitem">
                      <img
                        src={order_item.product_info.image_url}
                        alt=""
                        className="test"
                      />
                      <div className="orderitem-info">
                        <h6>{order_item.product_info.title_uk}</h6>
                        <p>{order_item.product_info.grammage} {t("ProductCard.weight")}</p>
                      </div>
                    </div>
                    <p className="orderitem-values">
                      {convertDateTime(order.order_datetime)}
                    </p>
                    <p className="orderitem-values">{order_item.amount}</p>
                    <p className="orderitem-values">
                      {order_item.product_info.price * order_item.amount}{" "}
                      {t("CartItem.price")}
                    </p>
                  </div>

                  <div className="blue-divider"></div>
                </>
              ))}
            </div>
          ))}

          {!userOrdersData && <Loading />}
        </div>

        <div
          className="personal-account-orders"
          style={
            contentClass === "sales--section"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {t("PersonalCabinet.Discounts")}
        </div>
      </div>
    </main>
  );
}

export default PersonalCabinet;
