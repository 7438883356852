import loginVector from "../../images/loginVector.png";
import facebookicon from "../../images/facebookicon.png";
import googleicon from "../../images/googleicon.png";
import passwordicon from "../../images/passwordicon.svg";
import emailicon from "../../images/emailicon.svg";
import usericon from "../../images/usericon.svg";
import phoneicon from "../../images/phoneicon.svg";

import passwordiconred from "../../images/passwordiconred.svg";
import emailiconred from "../../images/emailiconred.svg";
import usericonred from "../../images/usericonred.svg";
import phoneiconred from "../../images/passwordiconred.svg";

import RegisterInput from "../../Components/RegisterInput/RegisterInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactInputMask from "react-input-mask";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { login } from "../../features/user/userSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { googleLogin } from "../../features/user/userSlice";

import "./Register.css";

import { useTranslation } from "react-i18next";

const schema = yup.object({
  first_name: yup
    .string()
    .required("Це обов'язкове поле!")
    .matches(
      /^[a-zA-Zа-яА-ЯіїєґІЇЄҐ'-]+( [a-zA-Zа-яА-ЯіїєґІЇЄҐ'-]+)?( [a-zA-Zа-яА-ЯіїєґІЇЄҐ'-]+)?$/,
      "Погано введені дані"
    ),

  last_name: yup
    .string()
    .required("Це обов'язкове поле!")
    .matches(
      /^[a-zA-Zа-яА-ЯіїєґІЇЄҐ'-]+( [a-zA-Zа-яА-ЯіїєґІЇЄҐ'-]+)?( [a-zA-Zа-яА-ЯіїєґІЇЄҐ'-]+)?$/,
      "Погано введені дані"
    ),

  phone_number: yup
    .string()
    .required("Це обов'язкове поле!")
    .matches(/^\+38\s\(0\d{2}\)\s\d{3}-\d{2}-\d{2}$/, "Це обов'язкове поле!"),

  email: yup
    .string()
    .required("Це обов'язкове поле!")
    .matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Погано введені дані"),

  password: yup
    .string()
    .required("Це обов'язкове поле!")
    .min(6, "Пароль має містити принаймні 6 символів"),
  repeat_password: yup
    .string()
    .required("Це обов'язкове поле!")
    .oneOf([yup.ref("password")], "Паролі не збігаються"),
});

function Register() {
  const { t } = useTranslation();

  const { accessToken } = useSelector(selectUser);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userRegistered, setUserRegistered] = useState(false);

  useEffect(() => {
    if (accessToken) {
      navigate("/personal-account");
    }
  }, [accessToken, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    fetch("https://svizhabe.herokuapp.com/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setUserRegistered(true);
          throw new Error(t("Register.user_exist"));
        }
      })
      .then((result) => {
        const { email, password } = data;
        dispatch(login({ email, password }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      console.log(response);
      dispatch(googleLogin(response.access_token));
    },
    onError: (response) => console.log("Login Failed"),
  });

  return (
    <main>
      <div className="login-container">
        <div className="login-top">
          <h1 className="login-heading">{t("Register.Registration")}</h1>
          <img className="login-vector" src={loginVector} alt="" />
        </div>

        <div className="login-main">
          <form className="register-form" onSubmit={handleSubmit(onSubmit)}>
            <RegisterInput
              id="first_name"
              label={t("PersonalCabinet.name")}
              icon={errors.first_name ? usericonred : usericon}
              type="text"
              placeholder={t("Register.Name")}
              register={{ ...register("first_name") }}
              errorMessage={errors.first_name?.message}
            />

            <RegisterInput
              id="last_name"
              label={t("PersonalCabinet.last_name")}
              icon={errors.last_name ? usericonred : usericon}
              type="text"
              placeholder={t("Register.Last_name")}
              register={{ ...register("last_name") }}
              errorMessage={errors.last_name?.message}
            />

            <RegisterInput
              id="email"
              label={t("OrderPage.email")}
              icon={errors.email ? emailiconred : emailicon}
              type="text"
              placeholder={t("Register.Email")}
              register={{ ...register("email") }}
              errorMessage={errors.email?.message}
            />

            <div className="register-input">
              <label
                htmlFor="phone_number"
                className={errors.phone_number?.message ? "redlabel" : ""}
              >
                {t("OrderPage.Phone_number")}
              </label>
              <div
                className={
                  errors.phone_number?.message
                    ? "register-input-group-mid error-placeholder"
                    : "register-input-group-mid"
                }
              >
                <img
                  src={errors.phone_number ? phoneiconred : phoneicon}
                  alt=""
                />
                <ReactInputMask
                  mask="+38 (099) 999-99-99"
                  maskChar="_"
                  defaultValue="+38 (0__) ___-__-__"
                  placeholder="+38 (0__) ___-__-__"
                  {...register("phone_number")}
                />
              </div>

              <div className="register-input-group-bottom">
                <span
                  className={
                    errors.phone_number?.message
                      ? "red-register-underline"
                      : "register-underline"
                  }
                ></span>
                <span className="error-message">
                  {errors.phone_number?.message}
                </span>
              </div>
            </div>

            <RegisterInput
              id="password"
              label={t("LoginForm.Password")}
              icon={errors.password ? passwordiconred : passwordicon}
              type="password"
              placeholder={t("Register.Password")}
              register={{ ...register("password") }}
              errorMessage={errors.password?.message}
            />

            <RegisterInput
              id="repeat_password"
              label={t("Register.Confirm_password")}
              icon={errors.repeat_password ? passwordiconred : passwordicon}
              type="password"
              placeholder={t("Register.Confirm_password")}
              register={{ ...register("repeat_password") }}
              errorMessage={errors.repeat_password?.message}
            />

            <p
              className="already-exists"
              style={
                userRegistered ? { display: "block" } : { display: "none" }
              }
            >
              {t("Register.user_exist")}
            </p>

            <button className="wannaorder">{t("LoginForm.Registration")}</button>
          </form>

          <div className="login-oauth">
            <span className="mobile-delimiter"></span>

            <button className="facebook-auth">
              <a href="/">
                <img src={facebookicon} alt="" />
                {t("LoginForm.Sign")}
              </a>
            </button>

            <button onClick={() => handleGoogleLogin()} className="google-auth">
              <p>
                <img src={googleicon} alt="" />
                {t("LoginForm.Google")}
              </p>
            </button>
            <div className="mobile-divider">
              <span></span>
              <p>{t("LoginForm.or")}</p>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Register;
