import { useSelector, useDispatch } from "react-redux";
import { selectCart } from "../../features/shoppingCart/shoppingCartSlice";
import { clearCart } from "../../features/shoppingCart/shoppingCartSlice";
import { useEffect, useRef } from "react";
import CartItem from "../CartItem/CartItem";
import closeCart from "../../images/closeMenu.svg";
import toTrash from "../../images/delete.svg";
import "./ShoppingCart.css";
import { useTranslation } from "react-i18next";

function ShoppingCart({ visibilty, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { cartItems, total, amount } = useSelector(selectCart);

  let cartRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!cartRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div
      className="modal"
      style={{
        display: visibilty ? "block" : "none",
      }}
    >
      <div className="shoppingCart" ref={cartRef}>
        <div className="cart-header">
          <h2>{t("ShoppingCart.basket")}</h2>
          <button
            style={{ cursor: "pointer" }}
            className="btn close-btn"
            onClick={onClose}
          >
            <img src={closeCart} alt="Закрити" />
          </button>
        </div>
        <div className="cart-products">
          {amount < 1 && (
            <span className="empty-text">
              {t("ShoppingCart.now")}
            </span>
          )}
          {cartItems.map((item) => {
            return <CartItem key={item.id} {...item} />;
          })}
        </div>
        <div className="cart-actions">
          <p>{t("ShoppingCart.total")} {total} {t("CartItem.price")}</p>
          <button className="remove-btn" onClick={() => dispatch(clearCart())}>
            <img src={toTrash} alt="вилучити" />
            <span>{t("ShoppingCart.clear")}</span>
          </button>
        </div>
        <div className="cart-footer-buttons">
          <button onClick={onClose} className="btn checkout-btn">
            {t("ShoppingCart.back")}
          </button>
          {amount > 0 && (
            <a className="checkout-btn" href="/order">
              {t("ShoppingCart.to_issue")}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShoppingCart;
