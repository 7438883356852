import "./Input.css";

export default function Input({
  id,
  label,
  placeholder,
  type,
  defaultValue,
  register,
  errorMessage,
  style,
}) {
  return (
    <div className="input-group" style={style}>
      <label className={errorMessage ? "redlabel" : ""} htmlFor={id}>
        {label}
      </label>
      <div className="input-group-right">
        <input
          id={id}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
          className={errorMessage ? "redform" : ""}
          {...register}
        />
        <span className="error-message">{errorMessage}</span>
      </div>
    </div>
  );
}
