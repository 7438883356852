import "./RegisterInput.css";

export default function RegisterInput({
  id,
  label,
  icon,
  placeholder,
  type,
  register,
  errorMessage,
}) {
  return (
    <div className="register-input">
      <label className={errorMessage ? "redlabel" : ""} htmlFor={id}>
        {label}
      </label>
      <div className={errorMessage ? "register-input-group-mid error-placeholder" : "register-input-group-mid"}>
        <img src={icon} alt="" />
        <input
          className="register-input"
          type={type}
          id={id}
          placeholder={placeholder}
          {...register}
        />
      </div>
      <div className="register-input-group-bottom">
        <span
          className={
            errorMessage ? "red-register-underline" : "register-underline"
          }
        ></span>
        <span className="error-message">{errorMessage}</span>
      </div>
    </div>
  );
}
