import "./ExchangePolicyPage.css";
import { useTranslation } from "react-i18next";

export default function ExchangePolicyPage() {
  const { t } = useTranslation();
  return (
    <div className="exchange-policy-container">
      <h3 className="modal-heading" style={{ "margin-bottom": "30px" }}>
        {t("AboutUS.Exchange")}
      </h3>
      <div className="article-text __fullWidth text">
        <p>
          <strong>{t("ExchangePolicyPage.To_return")}</strong>
        </p>

        <p>{t("ExchangePolicyPage.Call_us")}</p>

        <p>&nbsp;</p>

        <p>
          <strong>{t("ExchangePolicyPage.Reasons")}</strong>
        </p>

        <p>
          {t("ExchangePolicyPage.The_received")}
          <br />
          {t("ExchangePolicyPage.The")}
          <br />
          {t("ExchangePolicyPage.The_received_goods")}
        </p>

        <p>&nbsp;</p>

        <p>
          <strong>{t("ExchangePolicyPage.Product_return")}</strong>
        </p>

        <p>
          {t("ExchangePolicyPage.If_the_customer")}
          <br />
          {t("ExchangePolicyPage.If_the")}
        </p>

        <p>&nbsp;</p>

        <p>
          <strong>{t("ExchangePolicyPage.Refund_procedure")}</strong>
        </p>

        <p>
          {t("ExchangePolicyPage.In_the_case")}
          <br />
          {t("ExchangePolicyPage.In_the")}
          <br />
          {t("ExchangePolicyPage.When_paying")}
        </p>
      </div>
    </div>
  );
}
