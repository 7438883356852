import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./CityInput.css";
import styles from "./CityInput.module.css";
import SelectInput from "../SelectInput/SelectInput";
import { useTranslation } from "react-i18next";

export default function CitiesInput({
  id,
  label,
  placeholder,
  type,
  defaultValue,
  register,
  errorMessage,
  setValue,
  handleShippingPointChange,
}) {
  const { t } = useTranslation();
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [shippingPoints, setShippingPoints] = useState([]);
  const [opened, setOpened] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(selectedCity);
  }, [selectedCity]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setOpened(false);
      }
    };

    if (opened) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [opened]);

  useEffect(() => {
    const fetchStreets = async () => {
      try {
        const response = await axios.post(
          "https://api.novaposhta.ua/v2.0/json/getCities",
          {
            apiKey: "93e83c596c1c4ffc1451f0319439bf15",
            modelName: "Address",
            calledMethod: "getCities",
            methodProperties: {
              FindByString: selectedCity,
              Limit: "20",
            },
          }
        );

        const response2 = await axios.post(
          "https://api.novaposhta.ua/v2.0/json/getWarehouses",
          {
            apiKey: "93e83c596c1c4ffc1451f0319439bf15",
            modelName: "Address",
            calledMethod: "getWarehouses",
            methodProperties: {
              CityName: selectedCity,
              TypeOfWarehouseRef: "841339c7-591a-42e2-8233-7a0a00f0ed6f",
            },
          }
        );

        const cityNames = response.data.data.flatMap(
          (curr) => curr.Description
        );

        setCities(cityNames);

        const warehousesNames = response2.data.data.flatMap(
          (curr) => curr.Description
        );

        setShippingPoints(warehousesNames);
      } catch (error) {
        console.error(error);
      }
    };

    if (selectedCity) {
      fetchStreets();
      handleShippingPointChange(shippingPoints[0]);
    }
  }, [selectedCity]);

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setOpened(false);
  };

  return (
    <div className="citiesinput">
      <div className="input-group">
        <label className={errorMessage ? "redlabel" : ""} htmlFor={id}>
          {label}
        </label>
        <div
          className={`cities-input-group-right input-group-right ${
            opened ? "cities-input-group-right-active" : ""
          }`}
          ref={inputRef}
        >
          <input
            value={selectedCity}
            id={id}
            placeholder={placeholder}
            type={type}
            defaultValue={defaultValue}
            className={errorMessage ? "redform" : ""}
            {...register}
            onChange={handleCityChange}
            setValue={setValue}
            onClick={() => setOpened(true)}
          />
          <div className={`${styles.items} ${opened ? styles.active : ""}`}>
            {cities.map((city, index) => (
              <li
                key={index}
                onClick={() => {
                  handleCityClick(city);
                }}
              >
                {city}
              </li>
            ))}
          </div>
          <span className="error-message">{errorMessage}</span>
        </div>
      </div>

      {shippingPoints.length > 0 && (
        <SelectInput
          selectId="shipping_point"
          label={t("CitiesInput")}
          arr={shippingPoints}
          onChange={handleShippingPointChange}
        />
      )}
    </div>
  );
}
