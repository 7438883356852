import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const savedLanguage = localStorage.getItem("language") || "ukr";

const baseUrlEnum = {
  en: "https://svizhabe.herokuapp.com/en/",
  deu: "https://svizhabe.herokuapp.com/de/",
  ukr: "https://svizhabe.herokuapp.com/uk/",
};

export const svizhaApi = createApi({
  reducerPath: "svizhaApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrlEnum[savedLanguage] }),
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => "subcategories",
    }),
    getProductsByCategoryId: builder.query({
      query: (category_id) => `category/${category_id}`,
    }),
    getProductById: builder.query({
      query: (product_id) => `products/${product_id}`,
    }),
    getMostPopular: builder.query({
      query: () => "products/most_popular",
    }),
    getProductsbyTags: builder.query({
      query: (product_id) => `products/tags/${product_id}`,
    }),
    searchProducts: builder.query({
      query: (productTitle) => `products/search/?product_title=${productTitle}`,
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetProductsByCategoryIdQuery,
  useGetProductByIdQuery,
  useGetMostPopular,
  useGetProductsbyTagsQuery,
  useSearchProductsQuery,
} = svizhaApi;
