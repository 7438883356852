import { Link as ScrollLink, Element } from "react-scroll";
import { useGetAllCategoriesQuery } from "../../app/store";
import "../Menu/Menu.css";
import Loading from "../../Components/Loading/Loading";

function MenuTestMainPage() {
  const { data, error, isLoading } = useGetAllCategoriesQuery();
  return (
    <main>
      <div className="subcategory--container">
        {isLoading ? (
          <Loading />
        ) : error ? (
          <p>An error occured</p>
        ) : (
          <>
            {data.results.length > 0 && (
              <div className="subcategory">
                <h1 className="subcategory--title" key={data.results[0].id}>
                  {data.results[0].title}
                </h1>

                {data.results[0].categories.map((cat) => (
                  <div className="category--card">
                    <ScrollLink
                      to={`#category${cat.category_id}`}
                      smooth={true}
                      duration={500}
                    >
                      <img src={cat.category_img} alt={cat.category_name} />
                      <h3 className="category--name">{cat.category_name}</h3>
                    </ScrollLink>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
}

export default MenuTestMainPage;
