import "./BuyUsPage.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BuyUsPage() {
  const { t } = useTranslation();

  return (
    <main>
      <div className="buy--us--container">
        <h1 className="buy--us--heading">{t("BuyUsPage.BuyUsPage")}</h1>
        <div className="buy--us--content">
          <div className="map">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2537.46067571792!2d30.59264011594538!3d50.50699469116964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4d1a94b128ef3%3A0x7adae328531c3aa!2z0YPQuy4g0JDRgNGF0LjRgtC10LrRgtC-0YDQsCDQndC40LrQvtC70LDQtdCy0LAsIDcsINCa0LjQtdCyLCAwMjAwMA!5e0!3m2!1sru!2sua!4v1681151096493!5m2!1sru!2sua"
            ></iframe>
          </div>
          <div className="buy-us-splitter">
            <div className="buy--us--text">
              <h2>{t("BuyUsPage.Delivery_in_Kyiv")}</h2>
              <h4>{t("BuyUsPage.Acceptance")}</h4>
              <p>
                {t("BuyUsPage.Delivery")}
                <br />
                {t("BuyUsPage.Place")}
              </p>

              <h4>{t("BuyUsPage.Where_we_deliver")}</h4>
              <p>{t("BuyUsPage.Delivery_works")}</p>

              <h4>{t("BuyUsPage.Delivery_days")}</h4>
              <p>{t("BuyUsPage.days")}</p>

              <h4>{t("BuyUsPage.Shipping_cost")}</h4>
              <p>
                {t("BuyUsPage.Kiev")}
                <br />
                {t("BuyUsPage.Orders")}
              </p>
            </div>

            <div className="buy--us--text">
              <h2>{t("BuyUsPage.Delivery_across")}</h2>
              <h4>{t("BuyUsPage.Acceptance_of_orders")}</h4>
              <p>
                {t("BuyUsPage.Minimum_order")}
                <br />
                {t("BuyUsPage.Shipping")}
                <br />
                {t("BuyUsPage.Our_products")}
                <br />
                {t("BuyUsPage.Unfortunately")}
              </p>

              <h4>{t("BuyUsPage.How_we_deliver")}</h4>
              <p>{t("BuyUsPage.We_deliver")}</p>

              <h4>{t("BuyUsPage.Shipping_days")}</h4>
              <p>{t("BuyUsPage.day")}</p>

              <h4>{t("BuyUsPage.cost")}</h4>
              <p>
                {t("BuyUsPage.The_cost")}
                <br />
                {t("BuyUsPage.additional")}
              </p>
            </div>
            <Link className="buy-us-wannaorder" to={"/menu"}>
              {t("main.I_want_to_order")}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default BuyUsPage;
