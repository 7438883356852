import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import axios from "axios";

const initialState = {
  accessToken: localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "",
  refreshToken: localStorage.getItem("refreshToken")
    ? localStorage.getItem("refreshToken")
    : "",
  user_id: localStorage.getItem("user_id")
    ? JSON.parse(localStorage.getItem("user_id"))
    : null,
};

export const login = createAsyncThunk("user/login", async (data) => {
  const response = await fetch("https://svizhabe.herokuapp.com/api/token/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();

  if (!response.ok) {
    throw new Error("Response error");
  }
  return json;
});

export const refreshTheToken = createAsyncThunk(
  "user/refreshToken",
  async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await fetch(
      "https://svizhabe.herokuapp.com/api/token/refresh/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refresh: refreshToken }),
      }
    );
    return await response.json();
  }
);

export const googleLogin = createAsyncThunk(
  "user/googleLogin",
  async (accessToken) => {
    const response = await axios.post(
      "https://svizhabe.herokuapp.com/api/google-auth/",
      {
        access_token: accessToken,
      }
    );
    const { access, refresh, user_id } = response.data;
    return { access, refresh, user_id };
  }
);

export const logout = createAsyncThunk("user/logout", async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const response = await fetch("https://svizhabe.herokuapp.com/api/logout/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh: refreshToken }),
  });
  return await response.json();
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeUser(state) {
      state.accessToken = null;
      localStorage.removeItem("accessToken");

      state.refreshToken = null;
      localStorage.removeItem("refreshToken");

      state.user_id = null;
      localStorage.removeItem("user_id");
    },
  },

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload) {
        state.accessToken = action.payload.access;
        localStorage.setItem("accessToken", state.accessToken);

        state.refreshToken = action.payload.refresh;
        localStorage.setItem("refreshToken", state.refreshToken);

        let decode = jwt_decode(state.accessToken);
        state.user_id = decode.user_id;
        localStorage.setItem("user_id", decode.user_id);
      }
    });
    builder.addCase(refreshTheToken.fulfilled, (state, action) => {
      state.accessToken = action.payload.access;
      localStorage.setItem("accessToken", state.accessToken);
      state.refreshToken = action.payload.refresh;
      localStorage.setItem("refreshToken", state.refreshToken);
    });
    builder.addCase(googleLogin.fulfilled, (state, action) => {
      state.accessToken = action.payload.access;
      localStorage.setItem("accessToken", state.accessToken);

      state.refreshToken = action.payload.refresh;
      localStorage.setItem("refreshToken", state.refreshToken);

      state.user_id = action.payload.user_id;
      localStorage.setItem("user_id", state.user_id);
    });
  },
});

export const { removeUser } = userSlice.actions;
export const selectUser = (state) => state.user;
export default userSlice.reducer;
