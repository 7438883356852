import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  total: 0,
  amount: 0,
  promoCode: null,
  discount: 0,
};

const shoppingCartSlice = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const itemInCart = state.cartItems.find(
        (item) => item.id === action.payload.id
      );
      if (itemInCart) {
        itemInCart.amount++;
        toast.info("Кількість товару у кошику збільшена на 1!");
      } else {
        state.cartItems.push({ ...action.payload, amount: 1 });
        toast.info("Товар додано до кошику!", {
          position: "bottom-right",
        });
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    clearCart: (state) => {
      state.cartItems = [];
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    removeItem: (state, action) => {
      const itemId = action.payload;
      state.cartItems = state.cartItems.filter((item) => item.id !== itemId);

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      return state;
    },
    increase: (state, { payload }) => {
      const cartItem = state.cartItems.find((item) => item.id === payload.id);
      cartItem.amount = cartItem.amount + 1;

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    set: (state, { payload }) => {
      const cartItem = state.cartItems.find((item) => item.id === payload.id);
      if (isNaN(payload.amount)) {
        cartItem.amount = "";
      } else {
        cartItem.amount = payload.amount;
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    decrease: (state, { payload }) => {
      const cartItem = state.cartItems.find((item) => item.id === payload.id);
      cartItem.amount = cartItem.amount - 1;

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },
    calculateTotals: (state) => {
      let amount = 0;
      let total = 0;
      state.cartItems.forEach((item) => {
        amount += item.amount;
        total += item.amount * item.discounted_price;
      });
      if (state.promoCode && state.promoCode.active) {
        const discount = (state.promoCode.percent / 100) * total;
        state.discount = Math.floor(discount);
        total -= discount;
      }

      state.amount = amount;
      state.total = Math.floor(total);
    },
    updateTotal: (state, action) => {
      state.total = action.payload;
    },
    applyPromoCode: (state, action) => {
      const promoCode = action.payload;

      state.promoCode = promoCode;
      const amount = state.cartItems.reduce(
        (total, item) => total + item.amount,
        0
      );
      let total = 0;

      state.cartItems.forEach((item) => {
        if (item.discount && state.promoCode && state.promoCode.active) {
          if (item.discount.discount_percentage > state.promoCode.percent) {
            total += item.amount * item.discounted_price;
            state.discount +=
              item.amount * (item.price - item.discounted_price);
          } else {
            const discount = Math.floor(
              item.amount * item.price * (state.promoCode.percent / 100)
            );
            total += item.amount * item.price - discount;
            state.discount += discount;
          }
        } else {
          const discount = Math.floor(
            item.amount * item.price * (state.promoCode.percent / 100)
          );
          total += item.amount * item.price - discount;
          state.discount += discount;
        }
      });

      // if (state.promoCode && state.promoCode.active) {
      //   const discount = (state.promoCode.percent / 100) * total;
      //   state.discount = Math.floor(discount);
      //   total -= discount;
      // }

      state.amount = amount;
      state.total = Math.floor(total);
    },
  },
});

export const {
  clearCart,
  removeItem,
  increase,
  decrease,
  set,
  calculateTotals,
  addToCart,
  updateTotal,
  applyPromoCode,
} = shoppingCartSlice.actions;
export const selectCart = (state) => state.shoppingCart;

export default shoppingCartSlice.reducer;
