import React, { useState, useEffect } from "react";
import logo from "../../images/logo.svg";
import cart from "../../images/cart.svg";
import blueCart from "../../images/blue-cart.svg";
import user from "../../images/user.svg";
import burger from "../../images/burger.svg";
import closeMenu from "../../images/closeMenu.svg";
import "./Header.css";
import { useSelector } from "react-redux";
import { selectCart } from "../../features/shoppingCart/shoppingCartSlice";
import { useTranslation } from "react-i18next";
import Search from "../Search/Search";
import lupa from "../../images/lupa.svg";

function Header({ setCartVisible }) {
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  const handleCloseSearch = () => {
    setShowSearch(false)
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    saveLanguageToLocalStorage(lng);
    window.location.reload();
  };

  const saveLanguageToLocalStorage = (language) => {
    localStorage.setItem("selectedLanguage", language);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const { amount } = useSelector(selectCart);
  const { accessToken } = useSelector((state) => state.user);

  return (
    <header className="header">
      <div className="header--container">
        <a className="logotype" href="/">
          <img src={logo} alt="Svizha" />
        </a>

        <nav>
          {showSearch ? (
            <Search close={handleCloseSearch}/>
          ) : (
            <button
              onClick={() => setShowSearch(!showSearch)}
              style={{ cursor: "pointer" }}
            >
              <img className="lupa-icon" src={lupa} alt="search" />
            </button>
          )}
          <button
            className={amount > 0 ? `cart-icon-filled` : `cart-icon`}
            style={{ cursor: "pointer" }}
            onClick={setCartVisible}
          >
            <span>{amount}</span>
            <img src={amount > 0 ? blueCart : cart} alt="cart" />
          </button>

          <button className="desktop--button">
            <a
              href={accessToken ? "/personal-account" : "/login"}
              style={{ marginRight: 0 }}
            >
              <img src={user} alt="user" />
            </a>
          </button>

          <button onClick={handleClick} className="burger">
            <img src={showMenu ? closeMenu : burger} alt="menu" />
          </button>

          {showMenu && (
            <div className={`dropdown-menu`}>
              <div className="header--container">
                <ul>
                  <li>
                    <a href="/menu">{t("header.menu")}</a>
                  </li>
                  <li>
                    <a href="about-us">{t("header.about_us")}</a>
                  </li>
                  <li>
                    <a href="buy-us">{t("header.where_to_buy")}</a>
                  </li>
                  <li>
                    <a href="for-partners">{t("header.for_partners")}</a>
                  </li>
                  <li>
                    <a href={accessToken ? "/logout" : "/login"}>
                      {accessToken ? t("header.logout") : t("header.login")}
                    </a>
                  </li>
                  <li>
                    <details className="language">
                      <summary>
                        {i18n.language === "ukr"
                          ? "УКР"
                          : i18n.language === "en"
                          ? "ENG"
                          : "DEU"}
                      </summary>
                      <div className="language_list">
                        <a href="#" onClick={() => changeLanguage("en")}>
                          <li
                            style={{
                              display:
                                i18n.language === "en" ? "none" : "block",
                            }}
                          >
                            ENG
                          </li>
                        </a>
                        <a href="#" onClick={() => changeLanguage("deu")}>
                          <li
                            style={{
                              display:
                                i18n.language === "deu" ? "none" : "block",
                            }}
                          >
                            DEU
                          </li>
                        </a>
                        <a href="#" onClick={() => changeLanguage("ukr")}>
                          <li
                            style={{
                              display:
                                i18n.language === "ukr" ? "none" : "block",
                            }}
                          >
                            УКР
                          </li>
                        </a>
                      </div>
                    </details>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Header;
