import logoBlack from "../../images/logoBlack.svg";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import mastercard from "../../images/mastercard.svg";
import visa from "../../images/visa.svg";
import "./MobileFooter.css";
import { useTranslation } from "react-i18next";

function MobileFooter() {
  const { t } = useTranslation();
  return (
    <footer className="mobile--footer">
      <div className="footer--mobile--container">
        <div className="footer--mobile--top">
          <div className="footer--mobile--logo">
            <a href="/">
              <img src={logoBlack} alt="Svizha" />
            </a>
            <p>© Svizha. All rights reserved</p>
          </div>

          <div className="footer--mobile--socials">
            <a href="https://www.facebook.com/sviizha">
              <img src={facebook} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/sviizha_">
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>

        <div className="footer--mobile--bottom">
          <div className="footer--mobile--bottom--el">
            <h6>{t("footer.Make_an_order")}</h6>
            <a href="tel:380-68-978-21-85">+38 (096) 053 53 46</a>
            <div className="mobile--payment--methods">
              <img src={visa} alt="visa" />
              <img src={mastercard} alt="mastercard" />
            </div>
          </div>

          <div className="footer--mobile--bottom--el">
            <h6>{t("footer.Work_schedule")}</h6>
            <p>{t("footer.from")}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default MobileFooter;
