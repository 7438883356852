import { Link } from "react-router-dom";
import { useGetAllCategoriesQuery } from "../../app/store";
import "./Menu.css";
import Loading from "../../Components/Loading/Loading";

function Menu() {
  const { data, error, isLoading } = useGetAllCategoriesQuery();
  return (
    <main>
      <div className="subcategory--container">
        {isLoading ? (
          <Loading />
        ) : error ? (
          <p>An error occured</p>
        ) : (
          <>
            {data.results.length > 0 && (
              <div className="subcategory">
                <h1 className="subcategory--title" key={data.results[0].id}>
                  {data.results[0].title}
                </h1>

                {data.results[0].categories.map((cat) => (
                  <div className="category--card">
                    <Link
                      key={cat.category_id}
                      to={{
                        pathname: `/category/${cat.category_id}`,
                        state: { catName: cat.category_name },
                      }}
                    >
                      <img src={cat.category_img} alt={cat.category_name} />
                      <h3 className="category--name">{cat.category_name}</h3>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </main>
  );
}

export default Menu;
